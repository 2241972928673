<template>
  <router-view></router-view>

  <div class="j-f-c-j-c" :style="{ width: pageWidth + 'px' }">
    <div
      class="j-f-c-j-c"
      style="
        border: 1px #ededed solid;
        width: 80%;
        margin-top: 100px;
        margin-bottom: 20px;
        padding-top: 100px;
        padding-bottom: 100px;
        border-radius: 30px;
        position: sticky;
      "
    >
      <div class="j-f-r-c">
        <div
          class="j-s-b"
          style="
            width: 300px;
            height: 50px;
            border: 1px #ededed solid;
            border-radius: 999px;
            padding: 0 3px 0 3px;
            box-shadow: 0 4px 4px rgb(97 112 241 / 10%);
          "
        >
          <input
            style="width: 90%; height: 100px; font-size: 20px"
            placeholder="请输入时间戳"
            @keyup="InputChange({ key: 'sjc', event: $event })"
            :value="sjc"
          />
        </div>
        <div
          class="j-f-c-j-c"
          style="
            width: 80px;
            height: 50px;
            background-color: #4975e9;
            color: #fff;
            border-radius: 999px;
            margin-left: 20px;
            margin-right: 20px;
            cursor: pointer;
          "
          @click="onsjc"
        >
          转换>>
        </div>
        <div
          class="j-s-b"
          style="
            width: 300px;
            height: 50px;
            border: 1px #ededed solid;
            border-radius: 999px;
            padding: 0 3px 0 3px;
            box-shadow: 0 4px 4px rgb(97 112 241 / 10%);
          "
        >
          <input
            style="width: 90%; height: 100px; font-size: 20px"
            placeholder="转换后的时间"
            :value="zhsjc"
          />
        </div>
      </div>

      <div style="position: absolute; top: 15px; font-size: 30px; color: #666">
        时间戳转换
      </div>
    </div>
  </div>

  <div class="j-f-c-j-c" :style="{ width: pageWidth + 'px' }">
    <div
      class="j-f-c-j-c"
      style="
        border: 1px #ededed solid;
        width: 80%;
        margin-top: 50px;
        margin-bottom: 20px;
        padding-top: 100px;
        padding-bottom: 100px;
        border-radius: 30px;
        position: sticky;
      "
    >
      <div
        class="j-s-b"
        style="
          
          border: 1px #ededed solid;
          border-radius: 10px;
          padding: 20px 20px 20px 20px;
          box-shadow: 0 4px 4px rgb(97 112 241 / 10%);
        "
      >
        <textarea
          style="width: 600px; height: 140px; font-size: 20px"
          placeholder="请输入需计算的文本"
          :value="string_" @keyup="string_InputChange({ key: 'string_', event: $event })"
        />
      </div>
      <div style="position: absolute; top: 15px; font-size: 30px; color: #666">
        字符串长度计算器 长度为：{{ string_length }}
      </div>
    </div>
  </div>

  <div class="j-f-c-j-c" :style="{ width: pageWidth + 'px' }">
    <div
      class="j-f-c-j-c"
      style="
        border: 1px #ededed solid;
        width: 80%;
        margin-top: 50px;
        margin-bottom: 20px;
        padding-top: 100px;
        padding-bottom: 100px;
        border-radius: 30px;
        position: sticky;
      "
    >
      <div style="font-size: 20px; color: #999999">正在开发中,敬请期待</div>
      <div style="position: absolute; top: 15px; font-size: 30px; color: #666">
        base64转换
      </div>
    </div>
  </div>

  <FootNav></FootNav>
</template>


<script>
import FootNav from './dist/FootNav.vue'
export default {
  data() {
    return {
        string_:'',
      string_length: 0,
      sjc: "1713779901000",
      zhsjc: "2024-04-22 17:58:21",
    };
  },
  components: {
    FootNav,
  },
  created() {},
  computed: {
    pageWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    async onsjc() {
      console.log(this.sjc);
      var sjc1 = new Date(Number(this.sjc) + 8 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      this.zhsjc = sjc1;
    },

    string_InputChange: function (e) {
      this.string_ = e.event.target.value;
      this.string_length=this.string_.length
    },

    InputChange: function (e) {
      this[e.key] = e.event.target.value;
    },
  },
};
</script>

<style scoped>
.dylsjl {
  color: #999;
}

.dylsjl:hover {
  color: #7998ee;
}

.buttons {
  width: 100px;
  height: 45px;
  background-color: #4975e9;
  border-radius: 5px;
  cursor: pointer;
}

.buttons:hover {
  background-color: #7998ee;
}

.inputs {
  width: 250px;
  height: 45px;
  border: 1px #b4bccc solid;

  border-radius: 5px;
}

input {
  padding-left: 20px;
  width: 80%;
  height: 45px;
  font-size: 14px;
  color: #606266;
}

.input-icon {
  width: 19px;
  height: 19px;
  margin-left: 20px;
  margin-right: 20px;
}

.name_right {
  margin-top: 20px;
  font-size: 12px;
  color: #fa525f;
}

.name_right text {
  padding: 5px 12px;
}

.hsbs {
  position: fixed;
  right: 10px;
  top: 60%;
  z-index: 9999;
  width: 80px;
  height: 80px;
  background-color: #4975e9;
  border-radius: 50%;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
}

.hsbs:hover {
  background-color: #7998ee;
  cursor: pointer;
}

.tabitem {
  align-items: center;
  color: #999;
  display: flex;
  font-size: 15px;
  padding: 5px 10px 5px 10px;
  margin: 0 8px 13px 8px;
  text-align: center;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
}

.tabitem:hover {
  color: #4975e9;
  cursor: pointer;
}

.tabitemSelect {
  align-items: center;
  color: #fff;
  background-color: #4975e9;
  display: flex;
  font-size: 15px;
  padding: 5px 15px 5px 15px;
  margin: 0 3px 13px 3px;
  text-align: center;
  border-radius: 999px;
  flex-grow: 0;
  flex-shrink: 0;
}

.tabitemSelect:hover {
  background-color: #7998ee;
  cursor: pointer;
}

.tabitems-lists {
  display: flex;
  justify-content: space-around;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.khzssc {
  font-size: 13px;
  color: #999;
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}

.khzssc:hover {
  color: #7998ee;
}

.colorbq {
  padding: 5px 10px 5px 10px;
  border: 1px #ddd solid;
  color: #999;
  border-radius: 5px;
  font-size: 13px;
  margin-right: 15px;
  cursor: pointer;
}

.colorbq:hover {
  border-color: rgb(99, 206, 255);
  color: rgb(17, 153, 238);
}

.colorbqSelect {
  border-color: rgb(99, 206, 255);
  background: rgb(230, 249, 255);
  color: rgb(17, 153, 238);
  cursor: pointer;
}

.Mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.39);
  z-index: 1000;
}

.windows {
  background-color: #fff;
  border-radius: 10px;
}

.ListItems {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 13px;
  border-bottom: 1px #ebeef5 solid;
  background-color: #fff;
}

.ListItems:hover {
  background-color: #f5f5f5;
}

.phone2:hover {
  color: #7998ee;
  cursor: pointer;
}

.textarea1 {
  border: 1px #ededed solid;
  width: 390px;
  height: 200px;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
}

.Maskbutton1 {
  width: 200px;
  height: 50px;
  background-color: #4975e9;
  color: #fff;
  border-radius: 10px;
  margin-top: 50px;
}

.Maskbutton1:hover {
  background-color: #7998ee;
  cursor: pointer;
}

.Mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.39);
  z-index: 1000;
}

.items:hover {
  color: #7998ee;
  cursor: pointer;
}

.Highlights0 {
  font-size: 12px;
  padding: 2px 5px 2px 5px;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  margin-right: 5px;
}

.Highlights1 {
  font-size: 12px;
  padding: 2px 5px 2px 5px;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  margin-right: 5px;
}

.list-item-button {
  border-radius: 5px;
  color: #333;
  text-decoration: underline;
}

.list-item-button:hover {
  color: #7998ee;
  cursor: pointer;
}

.no-vip-Mask-button {
  padding: 10px 20px 10px 20px;
  border-radius: 999px;
  border: 1px #4975e9 solid;
  color: #4975e9;
  box-shadow: 0 0 5px 3px rgba(73, 177, 233, 0.1);
  font-size: 15px;
}

.no-vip-Mask-button:hover {
  border: 1px #7998ee solid;
  color: #7998ee;
  cursor: pointer;
}

.no-vip-Mask {
  width: 100%;
  height: 333px;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 30px,
    rgba(255, 255, 255, 0)
  );
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 0;
}

td {
  border: 1px solid #f5f5f5;
  text-align: center;
  color: #333;
  font-size: 13px;
}
</style>