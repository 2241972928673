<template>
    <div class="Foot-Navigations j-f-c-c">
            <div class="Foot-Navigation">
               
                <div class="j-f-c-j-c"
                    style="padding-top:20px;padding-bottom: 20px;color: #999999;font-size: 13px;">
                    Copyright 2024  © 精心计算工具 . 版权所有 <a style="color:#999999;" href="https://beian.miit.gov.cn/#/Integrated/index">备案号 闽ICP备2024051566号</a>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'FootNav',
}
</script>

<style scoped>

.Foot-Navigations{
    width: 1920px;
    background-color: #292b33;
    padding-top: 20px;
    padding-bottom: 20px;
}
.Foot-Navigation{
    width: 1344px;
}
</style>